import React from 'react'

import PropTypes from 'prop-types'

import './card1.css'

const Card1 = (props) => {
  return (
    <div className={`card1-card ${props.rootClassName} `}>
      <h1 className="card1-text">{props.heading1}</h1>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="card1-image"
      />
      <span className="card1-text1 content-Light">{props.text}</span>
    </div>
  )
}

Card1.defaultProps = {
  heading1: 'Heading',
  text: 'Our agents are working 24 hours a day, 7 days a week, while you do the minimum.',
  image_src: '/playground_assets/stars-200h.png',
  image_alt: 'image',
  rootClassName: '',
}

Card1.propTypes = {
  heading1: PropTypes.string,
  text: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Card1
