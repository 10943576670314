import React from 'react'

import PropTypes from 'prop-types'

import './card.css'

const Card = (props) => {
  return (
    <div className="card-card">
      <h4 className="card-text heading4">{props.heading}</h4>
      <span className="card-text1 content-Light">{props.text}</span>
    </div>
  )
}

Card.defaultProps = {
  text: 'Our agents are working 24 hours a day, 7 days a week, while you do the minimum.',
  heading: '24/7 Service',
}

Card.propTypes = {
  text: PropTypes.string,
  heading: PropTypes.string,
}

export default Card
